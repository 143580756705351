import {AppBar, Button, fade, Theme, Toolbar} from '@material-ui/core'
import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        flexGrow: 1,
    },
    nav: {
        flexGrow: 1,
        position: 'relative',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    navButton: {
        marginRight: theme.spacing(1),
        backgroundColor: 'transparent',
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.15),
        },
        '&.active': {
            color: fade(theme.palette.common.black, 0.75),
            backgroundColor: fade(theme.palette.common.white, 0.9),
        }
    },
}))

interface TopBarProps {
    onSignOut: () => any
}

const TopBar = ({onSignOut}: TopBarProps) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const isRoute = (route: string) => location.pathname === route
    return (
        <AppBar position="static">
            <Toolbar>
                <div className={classes.nav}>
                    <Button variant="contained"
                            disableElevation
                            className={classNames(classes.navButton, {active: isRoute('/')})}
                            onClick={() => history.push('/')}>Api Keys</Button>
                    <Button variant="contained"
                            disableElevation
                            className={classNames(classes.navButton, {active: isRoute('/orders')})}
                            onClick={() => history.push('/orders')}>Orders</Button>
                </div>
                <Button color="inherit" onClick={onSignOut}>Sign out</Button>
            </Toolbar>
        </AppBar>
    )
}
export default TopBar
