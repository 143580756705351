
import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { config as AWSConfig } from 'aws-sdk'

export interface CognitoSession {
    credentials: {
        accessToken: string
        idToken: string
        refreshToken: string
    },
    user: {
        userName?: string
        email: string
    }
}
export interface CognitoConfig {
    region: string
    userPoolBaseUri: string
    clientId: string
    userPoolId: string
    tokenScopes: string[]
    redirectSignIn: string
    redirectSignOut: string
}
export default class CognitoUtils {
    constructor(private config: CognitoConfig) {
        AWSConfig.region = config.region
    }

// Creates a CognitoAuth instance
    createCognitoAuth(): CognitoAuth {
        const appWebDomain = this.config.userPoolBaseUri.replace('https://', '').replace('http://', '')
        return new CognitoAuth({
            UserPoolId: this.config.userPoolId,
            ClientId: this.config.clientId,
            AppWebDomain: appWebDomain,
            TokenScopesArray: this.config.tokenScopes,
            RedirectUriSignIn: this.config.redirectSignIn,
            RedirectUriSignOut: this.config.redirectSignOut
        })
    }

// Creates a CognitoUser instance
    createCognitoUser() {
        const pool = this.createCognitoUserPool()
        return pool.getCurrentUser()
    }

// Creates a CognitoUserPool instance
    createCognitoUserPool() {
        return new CognitoUserPool({
            UserPoolId: this.config.userPoolId,
            ClientId: this.config.clientId
        })
    }

// Get the URI of the hosted sign in screen
    getCognitoSignInUri() {
        return `${this.config.userPoolBaseUri}/login?response_type=code&client_id=${this.config.clientId}&redirect_uri=${this.config.redirectSignIn}`
    }

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
    parseCognitoWebResponse(href) {
        return new Promise((resolve, reject) => {
            const cognitoAuth = this.createCognitoAuth()

            // userHandler will trigger the promise
            cognitoAuth.userhandler = {
                onSuccess: function (result) {
                    resolve(result)
                },
                onFailure: function (err) {
                    reject(new Error('Failure parsing Cognito web response: ' + err))
                }
            }
            return cognitoAuth.parseCognitoWebResponse(href)
        })
    }

// Gets a new Cognito session
    getCognitoSession(): Promise<CognitoSession> {
        return new Promise((resolve, reject) => {
            const cognitoUser = this.createCognitoUser()
            if (!cognitoUser) {
                return reject(new Error('No user'))
            }
            cognitoUser.getSession((err, result) => {
                if (err || !result) {
                    reject(new Error('Failure getting Cognito session: ' + err))
                    return
                }

                // Resolve the promise with the session credentials
                // console.debug('Successfully got session: ' + JSON.stringify(result))
                const session = {
                    credentials: {
                        accessToken: result.accessToken.jwtToken,
                        idToken: result.idToken.jwtToken,
                        refreshToken: result.refreshToken.token
                    },
                    user: {
                        userName: result.idToken.payload['cognito:username'],
                        email: result.idToken.payload.email
                    }
                }
                resolve(session)
            })
        })
    }

// Sign out of the current session (will redirect to signout URI)
    signOutCognitoSession() {
        window.localStorage.clear()
        const url = `${this.config.userPoolBaseUri}/logout?response_type=code&redirect_uri=${encodeURIComponent(this.config.redirectSignOut)}&client_id=${this.config.clientId}`
        window.location.href = url
    }

}
