import React, {ChangeEventHandler, ClipboardEventHandler, useState} from 'react'
import {ApiKey, IntegrationType} from './models'
import {FormControl, InputLabel, MenuItem, Select, TextField, Theme} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {CreateIntegrationInput} from './actions'

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

interface IntegrationFormProps {
    apiKey: ApiKey
    onChange: (values: CreateIntegrationInput) => any
}

type InputElement = HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement | { name?: string, value: unknown }
const IntegrationForm = (props: IntegrationFormProps) => {
    const [state, setState] = useState<CreateIntegrationInput>({
        name: '',
        type: IntegrationType.Relay,
        apiKey: '',
        clientId: '',
    })

    const sendUpdate = () => {
        props.onChange(state)
    }

    const updateField = (field: keyof CreateIntegrationInput): ChangeEventHandler<InputElement> => e => {
        const value = e.target.value
        setState(prev => ({...prev, [field]: value}))
        setTimeout(sendUpdate)
    }
    const updateFieldPaste = (field: keyof CreateIntegrationInput): ClipboardEventHandler<any> => e => {
        const value = e.clipboardData.getData('Text')
        setState(prev => ({...prev, [field]: value}))
        setTimeout(sendUpdate)
    }

    const classes = useStyles()

    return (
        <React.Fragment>
            <TextField required
                       className={classes.formControl}
                       label="Name"
                       onChange={updateField('name')}
                       onPaste={updateFieldPaste('name')}/>
            <br/>
            <FormControl className={classes.formControl}>
                <InputLabel id="field-targetType">Integration Type</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.type}
                    onChange={updateField('type')}
                    label="Integration Type"
                >
                    <MenuItem value="relay">Relay</MenuItem>
                </Select>
            </FormControl>

            <br/>
            <TextField required
                       className={classes.formControl}
                       label="API Key"
                       onChange={updateField('apiKey')}
                       onPaste={updateFieldPaste('apiKey')}/>
            <br/>
            <TextField required
                       className={classes.formControl}
                       label="Producer Location Key" // @todo This is specific to relay
                       onChange={updateField('clientId')}
                       onPaste={updateFieldPaste('clientId')}/>
        </React.Fragment>
    )
}
export default IntegrationForm
