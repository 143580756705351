import {ApiKey} from './models'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table, TableBody,
  TableCell,
  TableRow, Theme,
} from '@material-ui/core'
import React from 'react'
import {ModalProps} from '@material-ui/core/Modal'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  tableHeading: {
    fontWeight: 'bold',
  },
}))

interface HelpDialogProps {
  apiKey?: ApiKey
  open: boolean
  onClose?: ModalProps['onClose']
}

const HelpDialog = ({apiKey, open, onClose}: HelpDialogProps) => {
  const classes = useStyles()
  if (!apiKey) {
    return (<React.Fragment/>)
  }
  const rows = [
    ['Endpoint', 'https://api.boostwire.com/global-food'],
    ['Protocol', 'json'],
    ['Protocol version', 'v2'],
    ['Server key', apiKey.apiKey],
  ]
  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>API Key Help</DialogTitle>
        <DialogContent dividers>
          To configure Gloria Food with this key, add a custom integration with these values:
          <Table>
            <TableBody>
              {rows.map(([heading, value]) => (
                  <TableRow key={heading}>
                    <TableCell component="th"
                               scope="row"
                               align="right"
                               className={classes.tableHeading}>{heading}</TableCell>
                    <TableCell align="left">{value}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose && onClose({}, 'escapeKeyDown')} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
  )
}
export default HelpDialog
