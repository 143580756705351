import {useEffect, useState} from 'react'
import {useAuth} from './context/AuthContext'
import {ApiKey, Order} from './models'
import * as actions from './actions'
import {CreateApiKeyInput, CreateIntegrationInput} from './actions'

export const useOrders = () => {
    const {session} = useAuth()
    const [items, setItems] = useState<Order[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null as Error|null)

    const fetchOrders = () => {
        setError(null)
        setLoading(true)
        return actions.fetchOrders(session)
            .then(setItems)
            .catch(setError)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (!session) {
            setError(new Error('No auth'))
            return;
        }
        fetchOrders()
    }, [session])

    return {
        orders: {items, loading, error},
        fetchOrders,
    }
}
export const useApiKeys = () => {
    const {session} = useAuth()
    const [items, setItems] = useState<ApiKey[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null as Error|null)

    const fetchApiKeys = () => {
        setError(null)
        setLoading(true)
        return actions.fetchApiKeys(session)
            .then(setItems)
            .catch(setError)
            .finally(() => setLoading(false))
    }

    const createApiKey = (input: CreateApiKeyInput) =>
        actions.createApiKey(session, input)
            .then(fetchApiKeys)

    const deleteApiKey = (keyId: string) =>
        actions.deleteApiKey(session, keyId)
            .then(fetchApiKeys)

    const createIntegration = (apiKeyId: string, input: CreateIntegrationInput) =>
        actions.createIntegration(session, apiKeyId, input)
            .then(fetchApiKeys)

    const deleteIntegration = (apiKeyId: string, integrationId: string) =>
        actions.deleteIntegration(session, apiKeyId, integrationId)
            .then(fetchApiKeys)

    useEffect(() => {
        if (!session) {
            setError(new Error('No auth'))
            return;
        }
        fetchApiKeys()
    }, [session])

    return {
        apiKeys: {items, loading, error},
        // fetchApiKeys,
        createApiKey,
        deleteApiKey,
        createIntegration,
        deleteIntegration,
    }
}
