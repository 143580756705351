import {GFOrder} from './lib/GFOrder'
import {RelayOrder} from './lib/relay'


export enum IntegrationType { Relay = 'relay' }
export interface Integration {
    id: string
    name: string
    type: IntegrationType
    apiKey: string
    clientId: string
}
export interface ApiKey {
    id: string
    owner: string
    integrations: Integration[]
    apiKey: string
}

export interface Order {
    id: string
    createdAt: Date
    accountId: string
    completed?: Date
    globalFoodOrder?: GFOrder
    stage?: string
    relayOrder?: RelayOrder
}
