import React, {useEffect, useState} from 'react'
import '@aws-amplify/ui/dist/style.css'
import './App.css'
import {ApiKey} from './models'
import {
    CircularProgress,
    Theme,
    Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ApiKeyCard from './ApiKeyCard'
import HelpDialog from './HelpDialog'
import {useApiKeys} from './hooks'
import {CreateIntegrationInput} from './actions'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    authText: {
        fontSize: '80%',
        marginRight: theme.spacing(2),
    },
    heading: {
        fontSize: theme.typography.h6.fontSize,
        margin: theme.spacing(2),
    },
}))

interface SelectedKeyState {
    id?: string
}

const KeysPage = () => {
    const classes = useStyles()
    const {apiKeys, ...apiKeyActions} = useApiKeys()
    const [selectedKey, setSelectedKey] = useState<SelectedKeyState>({})
    const [keyHelp, setKeyHelp] = useState(null as ApiKey | null)

    useEffect(() => {
        if (!apiKeys.loading && !apiKeys.error && !apiKeys.items.length) {
            apiKeyActions.createApiKey({})
        }
        // if (apiKeys.items.length) {
        //     setSelectedKey({id: apiKeys.items[0].id})
        // }
    }, [apiKeys])

    const createIntegration = (apiKeyId: string) => (input: CreateIntegrationInput) =>
        apiKeyActions.createIntegration(apiKeyId, input)
    const deleteIntegration = (apiKeyId: string, integrationId: string) =>
        apiKeyActions.deleteIntegration(apiKeyId, integrationId)

    const selectKey = (id: string) => {
        if (selectedKey.id === id) {
            return setSelectedKey({})
        }
        setSelectedKey({id})
    }

    if (apiKeys.loading) {
        return <div style={{textAlign: 'center', marginTop: '3em'}}>
            <CircularProgress/>
        </div>
    }

    return (
        <div>
            <HelpDialog apiKey={keyHelp} open={!!keyHelp} onClose={() => setKeyHelp(null)}/>
            <Typography variant="h6" className={classes.heading}>API Keys</Typography>
            {apiKeys.items.map(apiKey => (
                <ApiKeyCard key={apiKey.id}
                            apiKey={apiKey}
                            isSelected={selectedKey.id === apiKey.id}
                            onSelect={selectKey}
                            onHelp={(apiKey) => setKeyHelp(apiKey)}
                            onCreateIntegration={createIntegration(apiKey.id)}
                            onDeleteIntegration={deleteIntegration}/>
            ))}
        </div>
    )
}

export default KeysPage
