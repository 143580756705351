import React from 'react'
import '@aws-amplify/ui/dist/style.css'
import './App.css'
import {
    Theme, Typography, Card, CircularProgress, CardContent, List, ListItem,
    ListItemText, ListItemIcon,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import Moment from 'react-moment'
import {useOrders} from './hooks'
import {Order} from './models'

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontSize: theme.typography.h6.fontSize,
        margin: theme.spacing(2),
    },
    orderDetail: {
        marginRight: theme.spacing(2),
    }
}))


const OrdersPage = () => {
    const classes = useStyles()
    const {orders} = useOrders()

    if (orders.loading || orders.error) {
        return <div style={{textAlign: 'center', marginTop: '3em'}}>
            <CircularProgress/>
        </div>
    }

    const OrderListItem = ({order}: { order: Order }) => {
        let title = <div>
            {order.globalFoodOrder.client_first_name} {order.globalFoodOrder.client_last_name}
        </div>
        let description = <span>
            <span className={classes.orderDetail}><b>Time:</b> <Moment withTitle
                                                                       format="DD-MMM hh:mma">{order.createdAt}</Moment></span>
            <span className={classes.orderDetail}><b>ID:</b> {order.globalFoodOrder.id}</span>
            <span className={classes.orderDetail}><b>Total:</b> ${order.globalFoodOrder.total_price}</span>
            <span className={classes.orderDetail}><b>Phone:</b> {order.globalFoodOrder.client_phone}</span>
            {/*<br/>
            <span className={classes.orderDetail}><b>Notes:</b> {order.globalFoodOrder.instructions}</span>*/}
        </span>
        return <ListItem key={order.id}>
            <ListItemIcon>
                {order.completed ? <DoneIcon /> : <ErrorIcon />}
            </ListItemIcon>
            <ListItemText primary={title} secondary={description}/>
        </ListItem>
    }

    return (
        <div>
            <Typography variant="h6" className={classes.heading}>Orders</Typography>
            <Card>
                <CardContent>
                    <List>
                        {orders.items.map(order => <OrderListItem order={order} key={order.id}/>)}
                    </List>
                </CardContent>
            </Card>
        </div>
    )
}

export default OrdersPage
