import CognitoUtils, {CognitoConfig, CognitoSession} from './lib/cognito-utils'

let protocol = window.location.protocol
let hostName = window.location.hostname
if (window.location.port) {
    hostName += ':' + window.location.port
}
const url = `${protocol}//${hostName}`
const authConfig: CognitoConfig = {
    clientId: '135i7n08q8k860n08bjc36mmfp',
    region: 'us-east-1',
    userPoolBaseUri: 'https://restorelay.auth.us-east-1.amazoncognito.com',
    userPoolId: 'us-east-1_y1YrLkdCq',
    tokenScopes: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${url}/auth/`,
    redirectSignOut: `${url}/auth/`,
}
const cognitoUtils = new CognitoUtils(authConfig)

export function getSession(): Promise<CognitoSession> {
    return cognitoUtils.getCognitoSession()
}

// Initialise the Cognito session from a callback href
export function initSessionFromCallback(callbackUrl: string): Promise<CognitoSession> {
    return cognitoUtils.parseCognitoWebResponse(callbackUrl)
        .then(getSession)
}

export async function startAuthFlow() {
    window.location.href = cognitoUtils.getCognitoSignInUri()
}

export function signOut() {
    cognitoUtils.signOutCognitoSession()
}

export function redirectAfterAuth() {
    console.log('redirectAfterAuth', url)
    window.location.href = url
}
