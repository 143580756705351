import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon, ListItemSecondaryAction,
  ListItemText,
  Theme, Typography,
} from '@material-ui/core'
import React, {useState} from 'react'
import IntegrationForm from './IntegrationForm'
import {makeStyles} from '@material-ui/core/styles'
import {red} from '@material-ui/core/colors'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import HelpIcon from '@material-ui/icons/Help'
import {ApiKey, IntegrationType} from './models'
import {CreateIntegrationInput} from './actions'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    cursor: 'pointer',
  },
  authText: {
    fontSize: '80%',
    marginRight: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.h6.fontSize,
    margin: theme.spacing(2),
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardContent: {
    marginTop: -theme.spacing(4),
  },
  dl: {
    marginTop: 0,
  },
  dt: {
    display: 'inline-block',
    fontWeight: 'bold',
    margin: 0,
    verticalAlign: 'top',
  },
  dd: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: 0,
    paddingLeft: theme.spacing(0.2),
    marginRight: theme.spacing(2),
    whitespace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 150,
    overflow: 'hidden',
  },
  integrationName: {
  },
}))

interface ApiKeyCardProps {
  apiKey: ApiKey
  isSelected: boolean
  onSelect: (apiKeyId: string) => any
  onCreateIntegration: (input: CreateIntegrationInput) => Promise<any>
  onHelp: (apiKey: ApiKey) => any
  onDeleteIntegration: (apiKeyId: string, integrationId: string) => Promise<any>
}

const getAvatar = (integrationType: IntegrationType) => {
  let abbreviation = '?'
  if (integrationType === IntegrationType.Relay) {
    abbreviation = 'R'
  }
  return (
      <Avatar aria-label="recipe" style={{backgroundColor: red[500]}}>
        {abbreviation}
      </Avatar>
  )
}

const helpClicked = (fn: ApiKeyCardProps['onHelp'], apiKey: ApiKey) => (e: React.MouseEvent) => {
  e.stopPropagation()
  fn(apiKey)
}

const initialIntegrationValues: CreateIntegrationInput = {
  name: '',
  type: IntegrationType.Relay,
  apiKey: '',
  clientId: '',
}
const ApiKeyCard = ({apiKey, isSelected, ...props}: ApiKeyCardProps) => {
  const [integrationCreate, setIntegrationCreate] = useState({
    formOpen: false,
    values: initialIntegrationValues,
    creating: false,
  })
  const [integrationDelete, setIntegrationDelete] = useState({confirm: false, integrationId: '', deleting: false})
  const cancelCreateIntegration = () =>
    setIntegrationCreate({formOpen: false, values: initialIntegrationValues, creating: false})
  const cancelDeleteIntegration = () =>
    setIntegrationDelete({confirm: false, integrationId: '', deleting: false})
  const createIntegration = () => {
    setIntegrationCreate(prev => ({...prev, creating: true}))
    props.onCreateIntegration(integrationCreate.values)
        .then(cancelCreateIntegration)
  }
  const deleteIntegration = (integrationId: string) => () =>
    setIntegrationDelete({confirm: true, integrationId, deleting: false})
  const doDeleteIntegration = (integrationId: string) => () => {
    setIntegrationDelete(prev => ({...prev, deleting: true}))
    props.onDeleteIntegration(apiKey.id, integrationId)
        .then(cancelDeleteIntegration)
  }
  const classes = useStyles()
  return (
      <React.Fragment>
        <Dialog open={integrationCreate.formOpen} onClose={cancelCreateIntegration}>
          <DialogTitle>Add an integration target</DialogTitle>
          <DialogContent dividers>
            <IntegrationForm apiKey={apiKey}
                             onChange={values => setIntegrationCreate(prev => ({...prev, values}))}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelCreateIntegration} color="default">
              Cancel
            </Button>
            {integrationCreate.creating ? (
                <Button disabled color="primary">
                  Saving...
                </Button>
            ) : (
                <Button onClick={createIntegration} color="primary">
                  Save
                </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={integrationDelete.confirm} onClose={cancelDeleteIntegration}>
          <DialogTitle>Delete Integration</DialogTitle>
          <DialogContent dividers>
            Are you sure you want to delete this Integration? This integration target will no longer receive orders from
            the source.
            This action cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={cancelDeleteIntegration} color="default">
              Cancel
            </Button>
            {integrationDelete.deleting ? (
                <Button disabled color="primary">
                  Deleting...
                </Button>
            ) : (
                <Button onClick={doDeleteIntegration(integrationDelete.integrationId)} color="primary">
                  Delete
                </Button>
            )}
          </DialogActions>
        </Dialog>

        <Card>
          <CardHeader
              className={classes.title}
              onClick={() => props.onSelect(apiKey.id)}
              action={
                <React.Fragment>
                  <IconButton onClick={helpClicked(props.onHelp, apiKey)}>
                    <HelpIcon/>
                  </IconButton>
                </React.Fragment>
              }
              title={'Default Key'}
          />
          {isSelected && (
              <CardContent className={classes.cardContent}>
                <React.Fragment>
                  <List>
                    {apiKey.integrations && apiKey.integrations.map(t => (
                        <ListItem key={t.id}>
                          <ListItemIcon>{getAvatar(t.type)}</ListItemIcon>
                          <ListItemText disableTypography
                              primary={<Typography variant="h6" className={classes.integrationName}>{t.name}</Typography>}
                              secondary={
                                <div>
                                  <dl className={classes.dl}>
                                    <dt className={classes.dt}>Type:</dt> <dd className={classes.dd}>{t.type}</dd>
                                    <dt className={classes.dt}>Key:</dt> <dd className={classes.dd}>{t.apiKey}</dd>
                                    <dt className={classes.dt}>ClientId:</dt> <dd className={classes.dd}>{t.clientId}</dd>
                                  </dl>
                                </div>
                              }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={deleteIntegration(t.id)}>
                              <DeleteIcon/>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                  </List>
                  <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon/>}
                      onClick={() => setIntegrationCreate(prev => ({...prev, formOpen:true}))}
                  >
                    Add Integration
                  </Button>
                </React.Fragment>
              </CardContent>
          )}
        </Card>
      </React.Fragment>
  )
}
export default ApiKeyCard
