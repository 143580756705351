import React, {useEffect, useState} from 'react'
import {CircularProgress} from '@material-ui/core'
import * as auth from '../auth'
import {CognitoSession} from '../lib/cognito-utils'

interface AuthProviderData {
    session?: CognitoSession
    logout: () => any
}
const AuthContext = React.createContext<AuthProviderData>({
    logout: () => {},
})

interface AuthState {
    session?: CognitoSession
    error?: Error
}

export function AuthProvider(props) {
    const [authState, setAuthState] = useState<AuthState>({})
    useEffect(() => {
        auth.getSession()
            .then(session => {
                setAuthState({session})
            })
            .catch(error => {
                console.log('auth error', error)
                // Try to process auth code
                if (window.location.href.includes('code=')) {
                    return auth.initSessionFromCallback(window.location.href)
                        .then(session => {
                            auth.redirectAfterAuth()
                        })
                        .catch(err => {
                            console.log('error initing session', err)
                            auth.startAuthFlow()
                        })
                }
                auth.startAuthFlow()
            })
            .catch(error => setAuthState({error}))
    }, [])
    if (authState.error) {
        return <pre>Auth Error {authState.error.message}</pre>
    }
// code for pre-loading the user's information if we have their token in
// localStorage goes here
// 🚨 this is the important bit.
// Normally your provider components render the context provider with a value.
// But we post-pone rendering any of the children until after we've determined
// whether or not we have a user token and if we do, then we render a spinner
// while we go retrieve that user's information.
    if (!authState.session) {
        return <div style={{textAlign: 'center', marginTop: '3em'}}>
            Logging in...<br/>
            <CircularProgress/>
        </div>
    }
    const logout = () => {
        auth.signOut()
    }
    return (
        <AuthContext.Provider value={{session: authState.session, logout}} {...props} />
    )
}

export function useAuth() { return React.useContext(AuthContext)}
