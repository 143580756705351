import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {Container} from '@material-ui/core'
import './index.css'
import * as serviceWorker from './serviceWorker'
import Login from './Login'
import {AuthProvider, useAuth} from './context/AuthContext'
import KeysPage from './KeysPage'
import TopBar from './TopBar'
import OrdersPage from './OrdersPage'

const AppWrapper = () => {
    const auth = useAuth()

    if (!auth.session) {
        return <Login/>
    }

    const signOut = () => auth.logout()

    return (
        <Container maxWidth="md">
            <Router>
                <TopBar onSignOut={signOut}/>
                <Switch>
                    <Route exact path="/">
                        <KeysPage/>
                    </Route>
                    <Route path="/orders">
                        <OrdersPage/>
                    </Route>
                </Switch>
            </Router>
        </Container>
    )
}

ReactDOM.render(
    <AuthProvider>
        <AppWrapper/>
    </AuthProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
