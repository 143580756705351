import axios from 'axios'
import {CognitoConfig, CognitoSession} from './lib/cognito-utils'
import {ApiKey, IntegrationType} from './models'

export const baseUrl = 'https://manage-api.boostwire.com'

export const getAuthDetails = (): Promise<Partial<CognitoConfig>> =>
    axios(`${baseUrl}/`, {
        method: 'HEAD'
    }).then(res => res.data)

const authConfig = (auth: CognitoSession) => {
    if (!auth.credentials) {
        debugger
    }
    return {
        withCredentials: true,
        headers: {Authorization: auth.credentials.idToken},
    }
}

export interface CreateApiKeyInput {
}
export const createApiKey = (auth: CognitoSession, input: CreateApiKeyInput) =>
    axios(`${baseUrl}/api-keys`, {
        method: 'POST',
        data: input,
        ...authConfig(auth),
    })

export const deleteApiKey = (auth: CognitoSession, keyId: string) =>
    axios(`${baseUrl}/api-keys/${keyId}`, {
        method: 'DELETE',
        ...authConfig(auth),
    })

export const fetchApiKeys = (auth: CognitoSession): Promise<ApiKey[]> =>
    axios(`${baseUrl}/api-keys`, {
        method: 'GET',
        ...authConfig(auth),
    })
        .then(res => res.data.items)
        .catch(err => {
            console.warn('Error loading api keys', err)
            throw err
        })

export interface CreateIntegrationInput {
    name: string
    type: IntegrationType
    apiKey: string
    clientId: string
}
export const createIntegration = (auth: CognitoSession, apiKeyId: string, input: CreateIntegrationInput) =>
    axios(`${baseUrl}/api-keys/${apiKeyId}/integrations`, {
        method: 'POST',
        data: input,
        ...authConfig(auth),
    })

export const deleteIntegration = (auth: CognitoSession, apiKeyId: string, integrationId: string) =>
    axios(`${baseUrl}/api-keys/${apiKeyId}/integrations/${integrationId}`, {
        method: 'DELETE',
        ...authConfig(auth),
    })

export const fetchOrders = (auth: CognitoSession) =>
    axios(`${baseUrl}/orders`, {
        method: 'GET',
        ...authConfig(auth),
    }).then(res => res.data.items)
